export interface Member {
  id: string;
  email: string;
  password: string;
  name: string;
  nickname: string;
  phonenumber: string;
  birthday: string;
  joindate: string;
  unregister: boolean;
  authority: string;
}
export const members: Member[] = [
  {
    id: '1',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1998-05-23',
    joindate: '2024-04-29',
    unregister: true,
    authority: '',
  },
  {
    id: '2',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1998-05-23',
    joindate: '2024-04-29',
    unregister: true,
    authority: '',
  },
  {
    id: '3',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1919-01-01',
    joindate: '2024-04-29',
    unregister: true,
    authority: '',
  },
  {
    id: '4',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '박승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1919-01-01',
    joindate: '2024-04-29',
    unregister: true,
    authority: '',
  },
  {
    id: '5',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1919-01-01',
    joindate: '2024-04-29',
    unregister: true,
    authority: '',
  },
  {
    id: '6',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1919-01-01',
    joindate: '2024-04-29',
    unregister: true,
    authority: '',
  },
  {
    id: '7',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1919-01-01',
    joindate: '2024-04-29',
    unregister: true,
    authority: '',
  },
  {
    id: '8',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1919-01-01',
    joindate: '2024-04-29',
    unregister: false,
    authority: '',
  },
  {
    id: '9',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1919-01-01',
    joindate: '2024-04-29',
    unregister: true,
    authority: '',
  },
  {
    id: '10',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1919-01-01',
    joindate: '2024-04-29',
    unregister: true,
    authority: '',
  },
  {
    id: '11',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1919-01-01',
    joindate: '2024-04-29',
    unregister: false,
    authority: '',
  },
  {
    id: '12',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1919-01-01',
    joindate: '2024-04-29',
    unregister: false,
    authority: '',
  },
  {
    id: '13',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1919-01-01',
    joindate: '2024-04-29',
    unregister: false,
    authority: '',
  },
  {
    id: '14',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1998-05-23',
    joindate: '2024-04-29',
    unregister: false,
    authority: '',
  },
  {
    id: '15',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1919-01-01',
    joindate: '2024-04-29',
    unregister: true,
    authority: '',
  },
  {
    id: '16',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1998-05-23',
    joindate: '2024-04-29',
    unregister: true,
    authority: '',
  },
  {
    id: '17',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1919-01-01',
    joindate: '2024-04-29',
    unregister: true,
    authority: '',
  },
  {
    id: '18',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1919-01-01',
    joindate: '2024-04-29',
    unregister: true,
    authority: '',
  },
  {
    id: '19',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1919-01-01',
    joindate: '2024-04-29',
    unregister: true,
    authority: '',
  },
  {
    id: '20',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1919-01-01',
    joindate: '2024-04-29',
    unregister: true,
    authority: '',
  },
  {
    id: '21',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1919-01-01',
    joindate: '2024-04-29',
    unregister: true,
    authority: '',
  },
  {
    id: '22',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1919-01-01',
    joindate: '2024-04-29',
    unregister: false,
    authority: '',
  },
  {
    id: '23',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1919-01-01',
    joindate: '2024-04-29',
    unregister: true,
    authority: '',
  },
  {
    id: '24',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1919-01-01',
    joindate: '2024-04-29',
    unregister: true,
    authority: '',
  },
  {
    id: '25',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1919-01-01',
    joindate: '2024-04-29',
    unregister: true,
    authority: '',
  },
  {
    id: '26',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1919-01-01',
    joindate: '2024-04-29',
    unregister: true,
    authority: '',
  },
  {
    id: '27',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1919-01-01',
    joindate: '2024-04-29',
    unregister: true,
    authority: '',
  },
  {
    id: '28',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1919-01-01',
    joindate: '2024-04-29',
    unregister: true,
    authority: '',
  },
  {
    id: '29',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1919-01-01',
    joindate: '2024-04-29',
    unregister: true,
    authority: '',
  },
  {
    id: '30',
    email: 'jah02190@naver.com',
    password: '123456',
    name: '백승민',
    nickname: 'zl존승민',
    phonenumber: '01039125262',
    birthday: '1919-01-01',
    joindate: '2024-04-29',
    unregister: true,
    authority: '',
  },
];
